import type { IResponse } from '@Models/response.model';
import type { INote, IPayloadAddAnswer, TResponseAddAnswer } from '@Models/Note/note.model';
import type { IHeader } from '@Models/header.model';
import EHeader from '@Enums/headers';

import { base64EncodeUnicode } from '~coreUtils/base64';

// usługa służąca do zapisywania odpowiedzi na notatke w szczegółach danej czynności
export function saveAnswerService(isBailiff: boolean, payload: IPayloadAddAnswer) {
  const { $lkBiddingFetch } = useNuxtApp();

  const formData = new FormData();
  formData.append('parentMessageId', payload.parentMessageId.toString());
  formData.append('base64Description', base64EncodeUnicode(payload.description));

  if (payload.files && payload.files.length > 0) {
    payload.files.forEach((attachment) => {
      formData.append('files', new File([attachment], attachment.name, { type: attachment.type }));
    });
  }

  const endpointUrl = isBailiff ? 'add-self-answer' : 'add-answer';

  return $lkBiddingFetch<TResponseAddAnswer>(`/rest/bidder-message/${endpointUrl}`, {
    method: 'POST',
    body: formData
  });
}

export async function fetchNotesByItemId(data: { id: string; registryUnitId?: string }): Promise<unknown> {
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bidder-message/list`;
  const params: any = {
    itemId: data.id,
    parentOnly: true
  };
  if (data?.registryUnitId) {
    params.registryUnitId = data.registryUnitId;
  }
  const { useGet } = useHttpService();
  return useGet(url, params);
}

export function fetchSingleNoteById(noteId: number): Promise<IResponse<INote> | unknown> {
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bidder-message/${noteId}`;
  const { useGet } = useHttpService();
  return useGet(url);
}

export function saveNoteAsRead(id: string): Promise<unknown> {
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bidder-message/${id}/mark-as-read`;
  const { usePatch } = useHttpService();
  return usePatch(url);
}

export function saveAnswer(data: { isBailiff: boolean; formData: any }): Promise<IResponse<INote> | unknown> {
  const endpoint = data.isBailiff ? 'add-self-answer' : 'add-answer';
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bidder-message/${endpoint}`;
  const header: Partial<IHeader> = {
    'Content-Type': 'multipart/form-data',
    [EHeader.X_INVISIBLE_RECAPTCHA]: process.env.environment === 'test'
  };
  const { usePostForFormData } = useHttpService();

  return usePostForFormData(url, data.formData, header);
}
